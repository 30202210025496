import { Brand, Wrapper } from './styles';
import React, { useContext } from 'react';

import { Container } from 'components/common';
// import Image from '../../../common/Image';
import { Link } from 'gatsby';
import NavbarLinks from '../NavbarLinks';
import { ThemeContext } from 'providers/ThemeProvider';

// import hvikLogo from 'assets/thumbnail/hvik_logo.png';

const Navbar = ({ sidebar, toggle }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper as={Container} style={{ height: '100px' }}>
      <Brand as={Link} to="/" theme={theme}>
        Hindisvík
      </Brand>
      <NavbarLinks sidebar={sidebar} toggle={toggle} desktop />
    </Wrapper>
  );
};

export default Navbar;
