import React, { useContext } from 'react';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Button } from '../../../common/Button';
import { LangContext } from 'providers/LangProvider';
import { Wrapper } from './styles';

const NavbarLinks = ({ sidebar, toggle, desktop }) => {
  const { lang, toggleLang } = useContext(LangContext);

  const translations = {
    is: [
      { href: '#eigendur', text: 'Eigendur' },
      { href: '#sogubrot', text: 'Sögubrot' },
      { href: '#nu', text: 'Nú' },
    ],
    en: [
      { href: '#owners', text: 'Owners' },
      { href: '#history', text: 'History' },
      { href: '#the-present', text: 'The Present' },
    ],
  };

  const currentTrans = translations[lang];

  return (
    <Wrapper desktop={desktop}>
      {currentTrans.map(trans => (
        <AnchorLink href={trans.href}>{trans.text}</AnchorLink>
      ))}
      <div onClick={toggleLang} style={{ cursor: 'pointer' }}>
        <Button>{lang === 'is' ? 'EN' : 'IS'}</Button>
      </div>
    </Wrapper>
  );
};

export default NavbarLinks;
