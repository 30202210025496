import { Details, Flex, Links, Wrapper } from './styles';

import { Container } from '../../common/Container';
import React from 'react';

// import { Container } from 'components/common';

// import social from './social.json';

export const Footer = () => (
  <Wrapper>
    {/* <Flex as={Container}>
      <Details style={{ width: '100%', textAlign: 'right' }}>
        <h2 style={{ color: 'rgba(255, 255, 255, 0.9)' }}>Hindisvík, 2021</h2>
        <span>
          © All rights are reserved | {new Date().getFullYear()} | Made with{' '}
          <span aria-label="love" role="img">
            💖
          </span>{' '}
          by{' '}
          <a href="https://smakosh.com/?ref=portfolio-dev" rel="noopener noreferrer" target="_blank">
            Smakosh
          </a>
        </span>
      </Details>
      <Links>
        {social.map(({ id, name, link, icon }) => (
          <a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}>
            <img width="24" src={icon} alt={name} />
          </a>
        ))}
      </Links>
    </Flex> */}
  </Wrapper>
);
