import React from 'react';
import { Wrapper } from './styles';

const Hamburger = ({ sidebar, toggle }) => (
  <Wrapper sidebar={sidebar} onClick={() => toggle(!sidebar)}>
    {sidebar ? (
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
      </svg>
    ) : (
      <svg className="h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
      </svg>
    )}
  </Wrapper>
);

export default Hamburger;
