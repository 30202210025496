module.exports = {
  defaultTitle: 'Hindisvík',
  logo: '',
  author: 'KN',
  url: 'https://www.hindisvík.is',
  legalName: 'Hindisvík',
  defaultDescription:
    'Hindisvík er í einkaeign og eina jörðin á Íslandi sem ber þetta nafn. Eigendur Hindisvíkur eru Anna Norland og Helga Norland, Kristín Norland, Jón Norland og Halla Norland.',
  socialLinks: {
    twitter: null,
    github: null,
    linkedin: null,
    instagram: null,
    youtube: null,
    google: null,
  },
  googleAnalyticsID: 'prump',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: null,
    twitter: null,
  },
  address: {
    city: null,
    region: 'Vatnsnes',
    country: 'Iceland',
    zipCode: null,
  },
  contact: {
    email: 'jonn@sminor.is',
    phone: '8955890',
  },
  foundingDate: null,
};
